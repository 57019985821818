import React from "react"
import Layout from "../../Components/pageComponents/contentContainer"

import { useStaticQuery, graphql } from "gatsby"
import SolutionInfoSection from "../../Components/pageComponents/solutionsInfoSection"
import SolutionInfoSectionReversed from "../../Components/pageComponents/solutionsInfoSectionReversed"

// import Img from "gatsby-image"
// import style from "./styles.module.css"

export default function DisasterRecovery() {

  const Image = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "solutions/Unified Communications/Unified Communications Banner.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cloud: file(relativePath: {eq: "solutions/Unified Communications/Cloud PBX Stock.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hybrid: file(relativePath: {eq: "solutions/Unified Communications/Hybrid IPPBX Stock.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ip: file(relativePath: {eq: "solutions/Unified Communications/IP PBX Stock.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pabx: file(relativePath: {eq: "solutions/Unified Communications/PABX Stoxk.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`
  )
  const description = [
    "The only difference between the traditional PBX and PABX is the A that stands for Automatic for its electronic switching feature. The traditional PBX requires a human operator to transfer calls from one caller to another person. With PABX, human operators are deduced to automation and electronic switching.",
    "Legacy Private Automatic Branch Exchange (PABX) is not enough as a means of communication for SME/SMB as well as large organizations. Hence, the IPPBX unifies all means of communications in a single platform. This includes instant messaging (chat), presence, voice, mobility, audio, web collaboration, video conferencing, fixed-mobile convergence (FMC), voicemail, email, SMS, Fax, etc. Thereby giving you enhanced human-to-human communications in real-time or near real time.",
    "It is possible for a business to utilize their existing traditional Communication Infrastructure all while combining a hosted system / cloud at its own pace and enjoying the cost-effectiveness, scalability and services of the enterprise’s host.",
    "Cloud PBX is a fully hosted PBX System. It allows the deduction of hardware without compromising reliability and functionality of an Enterprise’s Unified Communications System. Cloud PBX’s Flexibility and Mobility are two of its best advantages. "
  ];

  return (
    <Layout image={Image.banner.childImageSharp.fluid}
      title="Unified Communications">

      <SolutionInfoSection heading="Solutions We Offer" title="PABX" description={description[0]}
        img={Image.pabx.childImageSharp.fluid} />

      <SolutionInfoSectionReversed title="IP PBX" description={description[1]}
        img={Image.ip.childImageSharp.fluid} />
      <SolutionInfoSection title="Hybrid IP PBX" description={description[2]}
        img={Image.hybrid.childImageSharp.fluid} />
      <SolutionInfoSectionReversed title="Cloud PBX" description={description[3]}
        img={Image.cloud.childImageSharp.fluid} />

    </Layout>

  )
}