import React from "react"
import Img from "gatsby-image"
import style from "./styles.module.css"
import { Container } from "reactstrap"

const SolutionsInfoSectionReversed = ({ title, description, img, children }) => {
  return (
    <Container
      fluid
      // className="pt-5 pb-5 pl-5 pr-5"
      style={{
        background: `rgb(243, 243, 243)`,
        paddingTop: `2rem`,
        paddingBottom: `3rem`,
      }}
    >
      <div className={style.solutionsInfoContainer}>
        <div className={style.solutionsInfoContent}>
          {img ? (
            <Img fluid={img} />
          ) : (
              <div />
            )}
        </div>
        <div className={style.solutionsInfoContent}>
          <h2>{title}</h2>
          <p>
            {description}
          </p>
          {children}
        </div>
      </div>
    </Container>
  )
}

export default SolutionsInfoSectionReversed